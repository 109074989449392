import React from 'react'

import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  LabelList,
} from 'recharts'
import { useHistory } from 'react-router-dom'
import { injectIntl, FormattedMessage } from 'react-intl'
import { requireAuthentication } from '../helpers/authentication'
import { useLocalStorage } from 'react-use'
import {
  Select,
  DatePicker,
  Form,
  Button,
  Flex,
  Row,
  Col,
  Typography,
  Empty,
  Badge,
  Radio,
  Table,
  ConfigProvider,
  theme as antdTheme,
  Breadcrumb,
  Collapse,
  Input,
  Tooltip,
} from 'antd'
import { HomeOutlined, LeftOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import objectSupport from 'dayjs/plugin/objectSupport'
import { useFaults } from '../hooks/Faults'
import { useUserPlant, useUserTestZones } from '../hooks/User'
import { useMakeModels } from '../hooks/MakeModels'
import { useStationInfo } from '../hooks/StationInfo'
import { useShifts } from '../hooks/Shifts'
import { openTabWithVehicleTestByResultId } from '../api/vehicle-tests-api'
import Loading, { Loadable } from '../components/Loading'
import { setModal } from '../actions/modal-actions'
import { MODAL_SELECT_PLANT, MODAL_SELECT_TESTZONE } from '../constants'
import ExportButton from './Export'
import { groupFaults } from './utils'
import { apiGetTerminology } from '../api/terminology'
import { locale } from '../Locale'
import { PlantButton } from './PlantButton'
import iconRedAsterisk from '../assets/images/red-asterisk.png'
import './styles.scss'
import { BRAND_BLUE, WHITE_COLOR, DARK_MODE } from '../constants'
import { useCheckPermission } from '../hooks/Permissions'
import { AddCaseButton, IssueButton, IssueStatus } from './IssueButton'

const { Text } = Typography

dayjs.extend(objectSupport)
dayjs.extend(utc)

const { Option } = Select
const { RangePicker } = DatePicker

const SEARCH_RESULT_CATEGORY = {
  component: 'component',
  device: 'device',
  harness: 'harness',
  generic: 'generic',
}

const TestZoneButton = ({ intl }) => {
  const [userTestZones] = useUserTestZones()
  const dispatch = useDispatch()
  const testZoneLabel = intl.formatMessage({
    id: 'faultReport.testZoneLabel',
    defaultMessage: 'Test Zones',
  })

  return (
    <div className="plant testzone">
      {testZoneLabel}:&nbsp;
      <Tooltip
        title={userTestZones.map((testZone) => testZone.description).toString()}
      >
        <Text
          underline
          onClick={(event) => {
            event.stopPropagation()
            dispatch(setModal(MODAL_SELECT_TESTZONE))
          }}
        >
          {userTestZones.length > 0
            ? userTestZones.length + ' selected'
            : 'Select test zones'}
        </Text>
      </Tooltip>
    </div>
  )
}

const ReportControl = ({ intl, searchTerm, setSearchTerm, faults }) => {
  const [userPlant] = useUserPlant()

  const { isLoading: makeModelsIsLoading, makeModels: allMakeModels } =
    useMakeModels({ plantName: userPlant })
  const { isLoading: shiftsIsLoading, shifts: plantShifts } = useShifts({
    plant_name: userPlant,
  })

  const { isLoading: stationInfoIsLoading, stationInfo: allStationInfo } =
    useStationInfo({ plant_name: userPlant })

  if (stationInfoIsLoading || makeModelsIsLoading || shiftsIsLoading) {
    return <Loading useBackgroundColor={false} />
  }
  return (
    <ReportControlForm
      intl={intl}
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      faults={faults}
      allStationInfo={allStationInfo}
      allMakeModels={allMakeModels}
      plantShifts={plantShifts}
    />
  )
}

function ReportControlForm({
  intl,
  searchTerm,
  setSearchTerm,
  faults,
  allStationInfo,
  allMakeModels,
  plantShifts,
}) {
  const [allDepartments, setAllDepartments] = React.useState([])
  const [allStationIds, setAllStationIds] = React.useState([])
  const [allTeams, setAllTeams] = React.useState([])
  const [allZones, setAllZones] = React.useState([])
  const [availableZones, setAvailableZones] = React.useState(allZones)
  const [availableTeams, setAvailableTeams] = React.useState(allTeams)
  const [form] = Form.useForm()
  const [, setUserTestZones] = useUserTestZones()

  React.useEffect(() => {
    if (!allStationInfo) {
      return
    }
    setAllStationIds([...new Set(allStationInfo.map((s) => s.station_id))])
    const depts = [
      ...new Set(
        allStationInfo.filter((s) => !!s.department).map((s) => s.department),
      ),
    ]
    setAllDepartments(depts)
    const teams = [
      ...new Set(allStationInfo.filter((s) => !!s.team).map((s) => s.team)),
    ]
    setAllTeams(teams)
    setAvailableTeams(teams)
    const zones = [
      ...new Set(allStationInfo.filter((s) => !!s.zone).map((s) => s.zone)),
    ]
    setAllZones(zones)
    setAvailableZones(zones)
  }, [allStationInfo])

  const todayStart = dayjs().startOf('day')
  const todayEnd = dayjs().endOf('day')

  const setTimeToDate = (date, time) => {
    const year = date.year()
    const month = date.month()
    const day = date.date()

    const hours = time.hour()
    const minutes = time.minute()
    const seconds = time.second()

    const combinedUTC = dayjs.utc({
      year,
      month,
      day,
      hour: hours,
      minute: minutes,
      second: seconds,
    })

    return combinedUTC
  }

  const getShiftDateTime = ({ period, currentShiftName, shifts }) => {
    const startDate = period[0].startOf('day')
    const endDate = period[1].endOf('day')
    const adjustedShifts = {}
    let daySwitched = false
    let allDayStart = null
    let allDayEnd = null
    for (const [shiftName, hours] of Object.entries(shifts)) {
      if (shiftName === allShiftsItem.name) {
        continue
      }
      const startHour = dayjs.utc(hours.start, 'HH:mm')
      const endHour = dayjs.utc(hours.end, 'HH:mm')
      let startAt = setTimeToDate(startDate, startHour)
      let endAt = setTimeToDate(endDate, endHour)

      // Shifts are list of start and end time that cover 24 hours.
      // For example, one shift can be in period 03:00-06:00.
      // BUT it is possible that shift starts at one day but ends at the next day.
      // In this case, its period will be something like this: 18:00-03:00.
      if (endAt.hour() < startAt.hour()) {
        // Cover case when shift starts at one day but ends at the next day.
        daySwitched = true
        endAt = endAt.add(1, 'days')
      } else if (daySwitched) {
        // Cover case if previous shift has date change.
        endAt = endAt.add(1, 'days')
        startAt = startAt.add(1, 'days')
      }

      // Build "All Shifts" entry from beginning of first shift and end of last shift
      if (allDayStart == null) allDayStart = startAt
      allDayEnd = endAt

      adjustedShifts[shiftName] = {
        startAt,
        endAt,
      }
    }

    adjustedShifts[allShiftsItem.name] = {
      startAt: allDayStart,
      endAt: allDayEnd,
    }
    return adjustedShifts[currentShiftName]
  }

  function isAllSelected(all, selected) {
    return (
      all.length === selected.length &&
      all.every((element, index) => element === selected[index])
    )
  }

  // Shifts hours are in UTC
  const allShiftsItem = {
    name: 'All Day',
    periods: {
      start: todayStart.utc().format('HH:mm'),
      end: todayEnd.utc().format('HH:mm'),
    },
  }

  const shifts = {
    ...{ [allShiftsItem.name]: allShiftsItem.periods },
    ...plantShifts.reduce((acc, shift) => {
      return {
        ...acc,
        [shift.name]: { start: shift.starts_at, end: shift.ends_at },
      }
    }, {}),
  }
  const allMakeModelIds = allMakeModels.map((makeModel) => makeModel.id)

  const updateAvailableTeamsAndZones = ({ selectedDepartment }) => {
    if (!selectedDepartment) {
      selectedDepartment = form.getFieldValue('department')
    }
    if (!selectedDepartment) {
      if (availableTeams.length != allTeams.length) {
        setAvailableTeams(allTeams)
      }
      if (availableZones.length != allZones.length) {
        setAvailableZones(allZones)
      }
      return
    }
    const availableStations = allStationInfo.filter(
      (s) => selectedDepartment === s.department,
    )
    setAvailableZones([
      ...new Set(availableStations.filter((s) => !!s.zone).map((s) => s.zone)),
    ])
    setAvailableTeams([
      ...new Set(availableStations.filter((s) => !!s.team).map((s) => s.team)),
    ])
  }

  const updateSelectedStations = ({ selectedZones, selectedTeams }) => {
    if (!selectedTeams) {
      selectedTeams = form.getFieldValue('teams')
    }
    if (!selectedZones) {
      selectedZones = form.getFieldValue('zones')
    }

    if (
      (!selectedZones || selectedZones.length === 0) &&
      (!selectedTeams || selectedTeams.length == 0)
    ) {
      return
    }

    const inSelectedZones = (station) => {
      return (
        !selectedZones ||
        selectedZones.length === 0 ||
        selectedZones.includes(station.zone)
      )
    }

    const inSelectedTeams = (station) => {
      return (
        !selectedTeams ||
        selectedTeams.length === 0 ||
        selectedTeams.includes(station.team)
      )
    }

    const existingStations = form.getFieldValue('stationIds')
    const stationIds = [
      ...new Set(
        allStationInfo
          .filter((s) => inSelectedZones(s) && inSelectedTeams(s))
          .map((s) => s.station_id)
          .concat(existingStations),
      ),
    ]
    form.setFieldValue('stationIds', stationIds)
  }

  // clear out plant-dependent search terms
  // (zone, test zone, department, stations)
  const onPlantSwitch = () => {
    form.setFieldValue('stationIds', [])
    form.setFieldValue('department', null)
    form.setFieldValue('zones', [])
    form.setFieldValue('teams', [])
  }

  const onFormClear = () => {
    onPlantSwitch()
    form.setFieldValue('makeModelIds', [])
    form.setFieldValue('shift', Object.keys(shifts)[0])
    form.setFieldValue('period', [todayStart.local(), todayEnd.local()])
  }

  const item = [
    {
      key: '1',
      label: (
        <Flex
          vertical={false}
          justify="space-between"
          data-testid="report-form-collapse"
        >
          <Flex vertical={false} gap={30}>
            <PlantButton intl={intl} onPlantSwitch={onPlantSwitch} />
            <TestZoneButton intl={intl} />
          </Flex>
          <ExportButton faults={faults} intl={intl} />
        </Flex>
      ),
      children: (
        <div className="report-control">
          <Form
            layout="vertical"
            form={form}
            initialValues={{
              stationIds: searchTerm.stationIds,
              makeModelIds:
                isAllSelected(allMakeModelIds, searchTerm.makeModelIds) === true
                  ? []
                  : searchTerm.makeModelIds,
              shift: searchTerm.shift || allShiftsItem.name,
              period: [
                (searchTerm.period.start && dayjs(searchTerm.period.start)) ||
                  todayStart,
                (searchTerm.period.end && dayjs(searchTerm.period.end)) ||
                  todayEnd,
              ],
              zones: searchTerm.zones,
              department: searchTerm.department,
              teams: searchTerm.teams,
              testZones: searchTerm.testZones,
            }}
            onFinish={(values) => {
              const {
                period,
                shift,
                stationIds,
                makeModelIds,
                zones,
                teams,
                department,
              } = values
              const shiftDateTime = getShiftDateTime({
                period,
                currentShiftName: shift,
                shifts,
              })
              setSearchTerm({
                start_at: shiftDateTime.startAt,
                end_at: shiftDateTime.endAt,
                stationIds: stationIds,
                makeModelIds:
                  makeModelIds.length === 0 ? allMakeModelIds : makeModelIds,
                zones: zones,
                teams: teams,
                department: department,
                shift,
                period: { start: period[0], end: period[1] },
              })
            }}
          >
            <Form.Item
              label={intl.formatMessage({
                id: 'faultReport.filters.departments.label',
              })}
              name="department"
            >
              <Select
                name="department"
                allowClear
                placeholder={intl.formatMessage({
                  id: 'faultReport.filters.departments.placeholder',
                  defaultMessage: 'All Departments',
                })}
                onChange={(selectedDepartment) => {
                  updateAvailableTeamsAndZones({ selectedDepartment })
                }}
              >
                {allDepartments.map((dept) => (
                  <Option key={dept} value={dept}>
                    {dept}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label={intl.formatMessage({
                id: 'faultReport.filters.zones.label',
              })}
              name="zones"
            >
              <Select
                name="zones"
                mode="multiple"
                allowClear
                placeholder={intl.formatMessage({
                  id: 'faultReport.filters.zones.placeholder',
                  defaultMessage: 'All Zones',
                })}
                onChange={(selectedZones) => {
                  updateSelectedStations({ selectedZones })
                }}
              >
                {availableZones.map((zone) => (
                  <Option key={zone} value={zone}>
                    {zone}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label={intl.formatMessage({
                id: 'faultReport.filters.teams.label',
              })}
              name="teams"
            >
              <Select
                name="teams"
                mode="multiple"
                allowClear
                placeholder={intl.formatMessage({
                  id: 'faultReport.filters.teams.placeholder',
                  defaultMessage: 'All Teams',
                })}
                onChange={(selectedTeams) => {
                  updateSelectedStations({ selectedTeams })
                }}
              >
                {availableTeams.map((team) => (
                  <Option key={team} value={team}>
                    {team}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label={intl.formatMessage({
                id: 'faultReport.filters.stations.label',
              })}
              name="stationIds"
            >
              <Select
                name="stationIds"
                mode="multiple"
                allowClear
                placeholder={intl.formatMessage({
                  id: 'faultReport.filters.stations.placeholder',
                })}
              >
                {allStationIds.map((station) => (
                  <Option key={station} value={station}>
                    {station}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label={intl.formatMessage({
                id: 'faultReport.filters.vehicles.label',
              })}
              name="makeModelIds"
            >
              <Select
                name="makeModelIds"
                mode="multiple"
                allowClear
                placeholder={intl.formatMessage({
                  id: 'faultReport.filters.vehicles.placeholder',
                })}
              >
                {allMakeModels.map((makeModel) => (
                  <Option key={makeModel.id} value={makeModel.id}>
                    {`${makeModel.make} - ${makeModel.model} - ${makeModel.year} - ${makeModel.phase}`}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item dependencies={['period']}>
              {({ getFieldValue }) => (
                <Form.Item
                  name="shift"
                  label={intl.formatMessage({
                    id: 'faultReport.filters.shifts.placeholder',
                  })}
                >
                  <Select
                    name="shift"
                    disabled={
                      !getFieldValue('period')[0].diff(
                        getFieldValue('period')[1],
                        'days',
                      ) == 0
                    }
                    placeholder="Please select interval"
                    data-cy="report-shift"
                  >
                    {Object.keys(shifts).map((shift) => (
                      <Option key={shift} value={shift}>
                        {ShiftLabel(intl, shift)}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </Form.Item>
            <Form.Item
              label={intl.formatMessage({
                id: 'faultReport.filters.shifts.period',
              })}
              name="period"
              layout="horizontal"
            >
              <RangePicker
                name="period"
                data-cy="report-date-range"
                allowClear={false}
                onChange={(period) => {
                  if (!period[0].isSame(period[1])) {
                    form.setFieldValue('shift', allShiftsItem.name)
                  }
                }}
                presets={[
                  {
                    label: 'Today',
                    value: [todayStart.local(), todayEnd.local()],
                  },
                ]}
              />
            </Form.Item>
            <Flex vertical={false} justify="space-between">
              <Button htmlType="submit" type="primary">
                {intl.formatMessage({ id: 'forms.submit' })}
              </Button>
              <Button onClick={onFormClear}>
                {intl.formatMessage({ id: 'forms.clearButton' })}
              </Button>
            </Flex>
          </Form>
        </div>
      ),
    },
  ]

  return <Collapse items={item} defaultActiveKey={['1']} />
}

function ShiftLabel(intl, shift) {
  let shiftTokens = shift.split(' ')
  if (shiftTokens.length === 2) {
    if (!isNaN(shiftTokens[1])) {
      return (
        intl.formatMessage({ id: 'faultReport.filters.shifts.label' }) +
        ' ' +
        shiftTokens[1]
      )
    } else {
      return intl.formatMessage({
        id: 'faultReport.filters.shifts.supersetLabel',
      })
    }
  }
  return shift
}

function FailureTimeCheckBox({ setPeriods, periods, row }) {
  const checked = periods.some(
    (period) => period.day === row.day && period.hour === row.hour,
  )

  const removePeriod = () => {
    setPeriods(
      periods.filter(
        (period) => !(period.day === row.day && period.hour === row.hour),
      ),
    )
  }
  const addPeriod = () => {
    setPeriods([...periods, { day: row.day, hour: row.hour }])
  }

  return (
    <label className="time-check-box">
      <input
        type="checkbox"
        checked={checked}
        onChange={() => (checked ? removePeriod() : addPeriod())}
      />
      {` ${row.hour}`}
    </label>
  )
}

function StationAndAlertIcon({ row }) {
  if (row.station_alert === 'send') {
    return (
      <div>
        <img style={{ width: '10px' }} src={iconRedAsterisk} alt="asterisk" />
        <span style={{ marginLeft: '0.5rem' }}>{row.station}</span>
      </div>
    )
  }
  return row.station
}

function FaultChart({ data }) {
  const countFailures = (data) => {
    return [
      {
        component: data.component.length,
        device: data.device.length,
        harness: data.harness.length,
        other: data.other.length,
      },
    ]
  }

  const labelFormatter = (value) => (value > 0 ? value : null)
  const labelStyle = { fill: 'white' }

  return (
    <div className="fault-chart">
      <ResponsiveContainer>
        <BarChart data={countFailures(data)} layout="vertical">
          <XAxis type="number" hide />
          <YAxis type="category" hide />
          <Bar
            stackId="stack"
            dataKey="component"
            fill="#0189E9"
            isAnimationActive={false}
          >
            <LabelList
              dataKey="component"
              style={labelStyle}
              formatter={labelFormatter}
            />
          </Bar>
          <Bar
            stackId="stack"
            dataKey="harness"
            fill="#FFC000"
            isAnimationActive={false}
          >
            <LabelList
              dataKey="harness"
              style={labelStyle}
              formatter={labelFormatter}
            />
          </Bar>
          <Bar
            stackId="stack"
            dataKey="other"
            fill="#D74053"
            isAnimationActive={false}
          >
            <LabelList
              dataKey="other"
              style={labelStyle}
              formatter={labelFormatter}
            />
          </Bar>
          <Bar
            stackId="stack"
            dataKey="device"
            fill="#9ba52a"
            isAnimationActive={false}
          >
            <LabelList
              dataKey="device"
              style={labelStyle}
              formatter={labelFormatter}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

const countFailures = (data) => {
  return Object.values(data).reduce(
    (dayFaultsCount, hourFaults) => {
      const hourFaultsCount = Object.values(hourFaults).reduce(
        (acc, fault) => {
          acc.component += fault.component.length
          acc.device += fault.device.length
          acc.harness += fault.harness.length
          acc.other += fault.other.length
          return acc
        },
        {
          component: 0,
          device: 0,
          other: 0,
          harness: 0,
        },
      )
      dayFaultsCount.component += hourFaultsCount.component
      dayFaultsCount.device += hourFaultsCount.device
      dayFaultsCount.harness += hourFaultsCount.harness
      dayFaultsCount.other += hourFaultsCount.other
      return dayFaultsCount
    },
    {
      component: 0,
      device: 0,
      other: 0,
      harness: 0,
    },
  )
}
function FailuresOverview({ intl, periods, setPeriods, data }) {
  const failuresCount = countFailures(data)

  if (Object.keys(data).length == 0) {
    return <div className="no-faults">No Faults found</div>
  }

  return (
    <div id="failures-overview">
      <div className="failures-count">
        <div className="count-type">
          {intl.formatMessage({ id: 'generic.allFailures' })}{' '}
          {Object.values(failuresCount).reduce(
            (partialSum, a) => partialSum + a,
            0,
          )}
        </div>
        <div className="count-type">
          <div className="component-dot"></div>
          {intl.formatMessage({ id: 'generic.component' })}{' '}
          {failuresCount.component}
        </div>
        <div className="count-type">
          <div className="harness-dot"></div>
          {intl.formatMessage({ id: 'generic.harness' })}{' '}
          {failuresCount.harness}
        </div>
        <div className="count-type">
          <div className="other-dot"></div>
          {intl.formatMessage({ id: 'generic.generic' })} {failuresCount.other}
        </div>
        <div className="count-type">
          <div className="device-dot"></div>
          {intl.formatMessage({ id: 'generic.device' })} {failuresCount.device}
        </div>
      </div>
      {Object.keys(data).map((day) => {
        return (
          <div className="failures-day" key={day}>
            <div className="failure-date">{day}</div>
            {Object.keys(data[day]).map((hour) => {
              return (
                <div className="failure-hour" key={`${day}-${hour}`}>
                  <FailureTimeCheckBox
                    setPeriods={setPeriods}
                    periods={periods}
                    row={data[day][hour]}
                  />
                  <FaultChart data={data[day][hour]} />
                </div>
              )
            })}
          </div>
        )
      })}
    </div>
  )
}

function countComponentFaults(faults) {
  return faults.reduce((counted, fault) => {
    const key = `${fault.component_id}::${fault.station}::${fault.reason}`
    if (!counted.get(key)) {
      counted.set(key, {
        type: 'component',
        id: fault.id,
        component_id: fault.component_id,
        attachedIssue: fault.attachedIssue,
        relatedIssues: fault.relatedIssues,
        station: fault.station,
        station_alert: fault.station_alert,
        reason: fault.reason,
        component_description: fault.component_description,
        translated_description: fault.translated_description,
        problems: [],
        key,
        test_zone: fault.hardware_location_id,
        short_code: fault.short_code,
        problem_code: fault.problem_code,
        fault_key: fault.fault_key,
        station_id: fault.station_id,
      })
    }
    counted.get(key).problems.push({
      type: 'component',
      vin: fault.vin,
      id: fault.id,
      vehicle_test_result_id: fault.vehicle_test_result_id,
      component_id: fault.component_id,
      created_at: fault.created_at,
      note: fault.note,
      created_by: fault.created_by,
      attachedIssue: fault.attachedIssue,
      relatedIssues: fault.relatedIssues,
      test_zone: fault.hardware_location_id,
      short_code: fault.short_code,
      problem_code: fault.problem_code,
      fault_key: fault.fault_key,
      station_id: fault.station_id,
    })
    return counted
  }, new Map())
}

function countDeviceFaults(faults) {
  return faults.reduce((counted, fault) => {
    const key = `${fault.device_name}::${fault.reason}`
    if (!counted.get(key)) {
      counted.set(key, {
        type: 'device',
        id: fault.id,
        device_name: fault.device_name,
        reason: fault.reason,
        description: fault.description,
        translated_description: fault.translated_description,
        problems: [],
        key,
        attachedIssue: fault.attachedIssue,
        relatedIssues: fault.relatedIssues,
        test_zone: fault.hardware_location_id,
        short_code: fault.short_code,
        problem_code: fault.problem_code,
        fault_key: fault.fault_key,
        station_id: fault.station_id,
      })
    }
    counted.get(key).problems.push({
      vin: fault.vin,
      id: fault.id,
      vehicle_test_result_id: fault.vehicle_test_result_id,
      created_at: fault.created_at,
      note: fault.note,
      created_by: fault.created_by,
      attachedIssue: fault.attachedIssue,
      relatedIssues: fault.relatedIssues,
      test_zone: fault.hardware_location_id,
      short_code: fault.short_code,
      problem_code: fault.problem_code,
      fault_key: fault.fault_key,
      station_id: fault.station_id,
    })
    return counted
  }, new Map())
}

function countHarnessFaults(faults) {
  return faults.reduce((counted, fault) => {
    const key = `${fault.harness}::${fault.description}::${fault.reason}`
    if (!counted.get(key)) {
      counted.set(key, {
        type: 'harness',
        id: fault.id,
        harness: fault.harness,
        description: fault.description,
        translated_description: fault.translated_description,
        reason: fault.reason,
        problems: [],
        key,
        attachedIssue: fault.attachedIssue,
        relatedIssues: fault.relatedIssues,
        test_zone: fault.hardware_location_id,
        short_code: fault.short_code,
        problem_code: fault.problem_code,
        fault_key: fault.fault_key,
        station_id: fault.station_id,
      })
    }
    counted.get(key).problems.push({
      vin: fault.vin,
      vehicle_test_result_id: fault.vehicle_test_result_id,
      created_at: fault.created_at,
      note: fault.note,
      created_by: fault.created_by,
      id: fault.id,
      attachedIssue: fault.attachedIssue,
      relatedIssues: fault.relatedIssues,
      test_zone: fault.hardware_location_id,
      short_code: fault.short_code,
      problem_code: fault.problem_code,
      fault_key: fault.fault_key,
      station_id: fault.station_id,
    })
    return counted
  }, new Map())
}

function countOtherFaults(faults) {
  return faults.reduce((counted, fault) => {
    const key = `${fault.reason}`
    if (!counted.get(key)) {
      counted.set(key, {
        type: 'other',
        reason: fault.reason,
        id: fault.id,
        problems: [],
        key,
        attachedIssue: fault.attachedIssue,
        relatedIssues: fault.relatedIssues,
        test_zone: fault.hardware_location_id,
        short_code: fault.short_code,
        problem_code: fault.problem_code,
        fault_key: fault.fault_key,
        station_id: fault.station_id,
      })
    }
    counted.get(key).problems.push({
      vin: fault.vin,
      vehicle_test_result_id: fault.vehicle_test_result_id,
      created_at: fault.created_at,
      note: fault.note,
      created_by: fault.created_by,
      attachedIssue: fault.attachedIssue,
      relatedIssues: fault.relatedIssues,
      id: fault.id,
      test_zone: fault.hardware_location_id,
      short_code: fault.short_code,
      problem_code: fault.problem_code,
      fault_key: fault.fault_key,
      station_id: fault.station_id,
    })
    return counted
  }, new Map())
}

function ComponentFaults({ faults, selectedFault, setFault, intl }) {
  const checkPermission = useCheckPermission()
  const canCreateIssues = checkPermission('issues:create')
  const columns = React.useMemo(() => {
    let cols = [
      {
        title: intl.formatMessage({
          id: 'faultReport.componentFaultsHeaders.components',
        }),
        render: (text, record) =>
          `${record.component_id}\n${record.translated_description ? record.translated_description : record.component_description}`,
        ellipsis: true,
        width: '25%',
      },
      {
        title: intl.formatMessage({
          id: 'faultReport.componentFaultsHeaders.station',
        }),
        render: (text, record) => StationAndAlertIcon({ row: record }),
        width: '15%',
      },
      {
        title: intl.formatMessage({
          id: 'faultReport.componentFaultsHeaders.reason',
        }),
        dataIndex: ['reason'],
        ellipsis: true,
        width: '23%',
      },
      {
        title: intl.formatMessage({
          id: 'faultReport.componentFaultsHeaders.count',
        }),
        render: (text, record) => record.problems.length,
        width: '10%',
      },
    ]

    if (canCreateIssues) {
      cols = cols.concat([
        {
          title: intl.formatMessage({
            id: 'faultReport.componentFaultsHeaders.status',
          }),
          render: (text, record) => IssueStatus({ fault: record }),
        },
        {
          title: '',
          id: 'issue-button-col',
          render: (text, record) => IssueButton({ fault: record }),
          sorter: false,
        },
      ])
    }

    return cols
  }, [selectedFault, setFault])
  return (
    <Table
      columns={columns}
      dataSource={faults}
      id="component-faults"
      rowSelection={{
        type: 'radio',
        onSelect: (record) => setFault(record),
        selectedRowKeys: [selectedFault.key],
      }}
      pagination={{
        pageSize: 4,
        hideOnSinglePage: true,
        showSizeChanger: false,
      }}
    />
  )
}

function OtherFaults({ faults, selectedFault, setFault, intl }) {
  const columns = React.useMemo(
    () => [
      {
        title: intl.formatMessage({
          id: 'faultReport.otherFaultsHeaders.generic',
        }),
        dataIndex: ['reason'],
        width: '60%',
      },
      {
        title: intl.formatMessage({
          id: 'faultReport.otherFaultsHeaders.count',
        }),
        render: (text, record) => record.problems.length,
      },
    ],
    [selectedFault, setFault],
  )
  return (
    <Table
      columns={columns}
      dataSource={faults}
      id="other-faults"
      rowSelection={{
        type: 'radio',
        onSelect: (record) => setFault(record),
        selectedRowKeys: [selectedFault.key],
      }}
      pagination={{
        pageSize: 4,
        hideOnSinglePage: true,
        showSizeChanger: false,
      }}
    />
  )
}

function HarnessFaults({ faults, selectedFault, setFault, intl }) {
  const checkPermission = useCheckPermission()
  const canCreateIssues = checkPermission('issues:create')
  const columns = React.useMemo(() => {
    let cols = [
      {
        title: intl.formatMessage({
          id: 'faultReport.harnessFaultsHeaders.harness',
        }),
        render: (text, record) =>
          `${record.harness}\n${record.translated_description ? record.translated_description : record.description}`,
        width: '24%',
        ellipsis: true,
      },
      {
        title: intl.formatMessage({
          id: 'faultReport.harnessFaultsHeaders.reason',
        }),
        dataIndex: ['reason'],
        ellipsis: true,
        width: '16%',
      },
      {
        title: intl.formatMessage({
          id: 'faultReport.harnessFaultsHeaders.description',
        }),
        dataIndex: ['description'],
        ellipsis: true,
        width: '22%',
      },
      {
        title: intl.formatMessage({
          id: 'faultReport.harnessFaultsHeaders.count',
        }),
        render: (text, record) => record.problems.length,
        width: '10%',
      },
    ]

    if (canCreateIssues) {
      cols = cols.concat([
        {
          title: intl.formatMessage({
            id: 'faultReport.harnessFaultsHeaders.status',
          }),
          render: (text, record) => IssueStatus({ fault: record }),
        },
        {
          title: '',
          id: 'issue-button-col',
          render: (text, record) => IssueButton({ fault: record }),
          sorter: false,
        },
      ])
    }

    return cols
  }, [selectedFault, setFault])
  return (
    <Table
      columns={columns}
      dataSource={faults}
      id="harness-faults"
      rowSelection={{
        type: 'radio',
        onSelect: (record) => setFault(record),
        selectedRowKeys: [selectedFault.key],
      }}
      pagination={{
        pageSize: 4,
        hideOnSinglePage: true,
        showSizeChanger: false,
      }}
    />
  )
}

function DeviceFaults({ faults, selectedFault, setFault, intl }) {
  const checkPermission = useCheckPermission()
  const canCreateIssues = checkPermission('issues:create')
  const columns = React.useMemo(() => {
    let cols = [
      {
        title: intl.formatMessage({
          id: 'faultReport.deviceFaultsHeaders.device',
        }),
        render: (text, record) => {
          const description = record.translated_description
            ? record.translated_description
            : record.description
              ? record.description
              : ''
          return `${record.device_name}\n${description}`
        },
        width: '24%',
        ellipsis: true,
      },
      {
        title: intl.formatMessage({
          id: 'faultReport.deviceFaultsHeaders.reason',
        }),
        width: '21%',
        dataIndex: ['reason'],
        ellipsis: true,
      },
      {
        title: intl.formatMessage({
          id: 'faultReport.deviceFaultsHeaders.description',
        }),
        dataIndex: ['description'],
        width: '18%',
        ellipsis: true,
      },
      {
        title: intl.formatMessage({
          id: 'faultReport.deviceFaultsHeaders.count',
        }),
        render: (text, record) => record.problems.length,
        width: '11%',
      },
    ]

    if (canCreateIssues) {
      cols = cols.concat([
        {
          title: intl.formatMessage({
            id: 'faultReport.deviceFaultsHeaders.status',
          }),
          render: (text, record) => IssueStatus({ fault: record }),
        },
        {
          title: '',
          id: 'issue-button-col',
          render: (text, record) => IssueButton({ fault: record }),
          sorter: false,
        },
      ])
    }

    return cols
  }, [selectedFault, setFault])
  return (
    <Table
      columns={columns}
      dataSource={faults}
      id="device-faults"
      rowSelection={{
        type: 'radio',
        onSelect: (record) => setFault(record),
        selectedRowKeys: [selectedFault.key],
      }}
      pagination={{
        pageSize: 4,
        hideOnSinglePage: true,
        showSizeChanger: false,
      }}
    />
  )
}

const VinURL = ({ vin, vehicleTestResultId, componentId }) => {
  return (
    <a
      className="report-vin-anchor"
      onClick={(event) => {
        event.preventDefault()
        openTabWithVehicleTestByResultId({ vehicleTestResultId, componentId })
      }}
    >
      {vin}
    </a>
  )
}

function VehicleTestTable({ data, intl, parentFault, setFault }) {
  const getIssue = () => {
    return parentFault.attachedIssue
  }

  const columns = React.useMemo(
    () => [
      {
        title: intl.formatMessage({
          id: 'faultReport.vehicleTestTableHeaders.vin',
        }),
        render: (text, record) => (
          <VinURL
            vin={record.vin}
            vehicleTestResultId={record.vehicle_test_result_id}
            componentId={
              record.type === 'component' ? record.component_id : null
            }
          />
        ),
        width: '22%',
      },
      {
        title: intl.formatMessage({
          id: 'faultReport.vehicleTestTableHeaders.timestamp',
        }),
        render: (text, record) => {
          return (
            <>
              {record.test_zone}
              <br />
              <span className="created-at-lbl">
                {dayjs(record.created_at).format('MM/DD/YYYY h:mma')}
              </span>
            </>
          )
        },
      },
      {
        title: intl.formatMessage({
          id: 'faultReport.vehicleTestTableHeaders.notes',
        }),
        dataIndex: ['note'],
        ellipsis: true,
      },
      {
        title: intl.formatMessage({
          id: 'faultReport.vehicleTestTableHeaders.userId',
        }),
        dataIndex: ['created_by'],
      },
      {
        title: '',
        id: 'add-case-btn-col',
        render: (text, record) =>
          AddCaseButton({
            parentFault,
            fault: record,
            issue: getIssue(),
            setFault,
          }),
      },
    ],
    [],
  )

  return (
    <Table
      columns={columns}
      dataSource={data}
      id="report-vehicle-tests"
      pagination={{
        pageSize: 2,
        hideOnSinglePage: true,
        showSizeChanger: false,
      }}
    />
  )
}

const SelectedFault = ({ fault, intl }) => {
  let text = ''
  if (fault.type === 'component') {
    text = `${intl.formatMessage({ id: 'faultReport.selectedFaultText.component' })} "${fault.component_id} - ${fault.component_description} - ${fault.reason}"`
  } else if (fault.type === 'harness') {
    text = `${intl.formatMessage({ id: 'faultReport.selectedFaultText.harness' })} "${fault.harness} - ${fault.reason}"`
  } else if (fault.type === 'device') {
    text = `${intl.formatMessage({ id: 'faultReport.selectedFaultText.device' })} "${fault.device_name} - ${fault.reason}"`
  } else if (fault.type === 'other') {
    text = `${intl.formatMessage({ id: 'faultReport.selectedFaultText.other' })} ${fault.reason}`
  }

  return (
    <div id="selected-fault">
      {intl.formatMessage({ id: 'faultReport.selectedFaultText.selected' })}:{' '}
      {text}
    </div>
  )
}

const Counter = ({ isLoading, results, theme }) => {
  return (
    <Loadable isLoading={isLoading} showText={false}>
      <Badge
        color={theme == DARK_MODE ? 'white' : '#0189e9'}
        count={results ? results.length : 0}
        showZero
      />
    </Loadable>
  )
}

const GenerateTerminology = (faults) =>
  new Promise((resolve) => {
    let requestBody = {}
    let faultObject = {}
    faults.forEach((fault) => {
      let faultKey
      switch (fault.type) {
        case 'component':
          faultKey = fault.component_id
          if (requestBody[fault.vin]) {
            requestBody[fault.vin].push(fault.component_id)
          } else {
            requestBody[fault.vin] = [fault.component_id]
          }
          break
        case 'device':
          faultKey = fault.device_name
          if (requestBody[fault.vin]) {
            requestBody[fault.vin].push(fault.device_name)
          } else {
            requestBody[fault.vin] = [fault.device_name]
          }
          break
        case 'harness':
          faultKey = fault.harness
          if (requestBody[fault.vin]) {
            requestBody[fault.vin].push(fault.harness)
          } else {
            requestBody[fault.vin] = [fault.harness]
          }
          break
      }
      if (faultObject[faultKey]) {
        faultObject[faultKey].push(fault)
      } else {
        faultObject[faultKey] = [fault]
      }
    })

    const promiseArray = []
    for (const [key, value] of Object.entries(requestBody)) {
      promiseArray.push(
        apiGetTerminology({ vin: key, codes: value, language: locale }),
      )
    }

    Promise.allSettled(promiseArray).then((results) => {
      results.forEach((resultEntry) => {
        if (resultEntry.status == 'fulfilled') {
          Object.values(resultEntry.value).forEach((result) => {
            for (let i = 0; i < faultObject[result.code].length; i++) {
              switch (faultObject[result.code][i].type) {
                case 'component':
                  if (faultObject[result.code][i].component_id == result.code)
                    faultObject[result.code][i]['translated_description'] =
                      result.description
                  break
                case 'device':
                  if (faultObject[result.code][i].device_name == result.code)
                    faultObject[result.code][i]['translated_description'] =
                      result.description
                  break
                case 'harness':
                  if (faultObject[result.code][i].harness == result.code)
                    faultObject[result.code][i]['translated_description'] =
                      result.description
                  break
                default:
                  break
              }
            }
          })
        }
      })
      resolve(Object.values(faultObject).flat())
    })
  })

function Faults({ report, periods, intl }) {
  const [selectedFault, setFault] = React.useState('')
  const theme = useSelector((state) => state.app.theme, shallowEqual)
  const [componentFaults, updateComponentFaults] = React.useState([])
  const [harnessFaults, updateHarnessFaults] = React.useState([])
  const [otherFaults, updateOtherFaults] = React.useState([])
  const [deviceFaults, updateDeviceFaults] = React.useState([])
  const [isLoadingTerminology, updateLoadingStatus] = React.useState(true)
  const [searchStr, updateSearchStr] = React.useState('')
  const [searchResultCategory, setSearchResultCategory] = React.useState(
    SEARCH_RESULT_CATEGORY.component,
  )

  const [faults, updateFaults] = React.useState({
    component: [],
    harness: [],
    other: [],
    device: [],
  })

  React.useEffect(() => {
    const initialFaults = getFaultsFromPeriod()
    setFault('')
    updateSearchStr('')
    updateLoadingStatus(true)
    const requestBody = [
      ...initialFaults.component,
      ...initialFaults.device,
      ...initialFaults.harness,
    ]
    if (requestBody.length != 0) {
      GenerateTerminology(requestBody).then((results) => {
        const updatedFaultList = getUpdatedFaultList(results, initialFaults)
        updateFaults((faults) => {
          faults.component = [
            ...countComponentFaults(updatedFaultList.component).values(),
          ]
          faults.harness = [
            ...countHarnessFaults(updatedFaultList.harness).values(),
          ]
          faults.other = [...countOtherFaults(updatedFaultList.other).values()]
          faults.device = [
            ...countDeviceFaults(updatedFaultList.device).values(),
          ]
          return faults
        })
        updateFilteredFaults(updatedFaultList)
        updateLoadingStatus(false)
      })
    } else {
      updateFaults(initialFaults)
      updateFilteredFaults(initialFaults)
      updateLoadingStatus(false)
    }
  }, [periods])

  const getUpdatedFaultList = (results, faults) => {
    const updatedFaultList = {
      component: [],
      harness: [],
      other: faults.other,
      device: [],
    }
    results.forEach((result) => {
      switch (result.type) {
        case 'component':
          updatedFaultList.component.push(result)
          break
        case 'device':
          updatedFaultList.device.push(result)
          break
        case 'harness':
          updatedFaultList.harness.push(result)
          break
      }
    })
    return updatedFaultList
  }

  const getFaultsFromPeriod = () => {
    return periods.reduce(
      (acc, period) => {
        acc.component.push(...report[period.day][period.hour].component)
        acc.harness.push(...report[period.day][period.hour].harness)
        acc.other.push(...report[period.day][period.hour].other)
        acc.device.push(...report[period.day][period.hour].device)
        return acc
      },
      { component: [], harness: [], other: [], device: [] },
    )
  }

  const onSearch = () => {
    const filteredFaults = { ...faults }
    const searchStrLower = searchStr.toLocaleLowerCase()
    let foundSelectFault = false

    filteredFaults.component = filteredFaults.component.filter((fault) => {
      const faultSearch =
        fault.component_description
          .toLocaleLowerCase()
          .includes(searchStrLower) ||
        fault.component_id.toLocaleLowerCase().includes(searchStrLower) ||
        fault.reason.toLocaleLowerCase().includes(searchStrLower) ||
        (fault.translated_description &&
          fault.translated_description
            .toLocaleLowerCase()
            .includes(searchStrLower))

      if (faultSearch && fault.id == selectedFault.id) {
        foundSelectFault = true
      }

      return faultSearch
    })

    filteredFaults.harness = filteredFaults.harness.filter((fault) => {
      const faultSearch =
        (fault.description &&
          fault.description.toLocaleLowerCase().includes(searchStrLower)) ||
        fault.harness.toLocaleLowerCase().includes(searchStrLower) ||
        fault.reason.toLocaleLowerCase().includes(searchStrLower) ||
        (fault.translated_description &&
          fault.translated_description
            .toLocaleLowerCase()
            .includes(searchStrLower))

      if (faultSearch && fault.id == selectedFault.id) {
        foundSelectFault = true
      }
      return faultSearch
    })

    filteredFaults.device = filteredFaults.device.filter((fault) => {
      const faultSearch =
        (fault.description &&
          fault.description.toLocaleLowerCase().includes(searchStrLower)) ||
        fault.device_name.toLocaleLowerCase().includes(searchStrLower) ||
        fault.reason.toLocaleLowerCase().includes(searchStrLower) ||
        (fault.translated_description &&
          fault.translated_description
            .toLocaleLowerCase()
            .includes(searchStrLower))

      if (faultSearch && fault.id == selectedFault.id) {
        foundSelectFault = true
      }
      return faultSearch
    })

    filteredFaults.other = filteredFaults.other.filter((fault) => {
      const faultSearch = fault.reason
        .toLocaleLowerCase()
        .includes(searchStrLower)
      if (faultSearch && fault.id == selectedFault.id) {
        foundSelectFault = true
      }
      return faultSearch
    })
    if (!foundSelectFault) {
      setFault('')
    }
    updateComponentFaults(filteredFaults.component)
    updateHarnessFaults(filteredFaults.harness)
    updateOtherFaults(filteredFaults.other)
    updateDeviceFaults(filteredFaults.device)
  }

  const updateFilteredFaults = (filteredFaults) => {
    updateComponentFaults([
      ...countComponentFaults(filteredFaults.component).values(),
    ])
    updateHarnessFaults([
      ...countHarnessFaults(filteredFaults.harness).values(),
    ])
    updateOtherFaults([...countOtherFaults(filteredFaults.other).values()])
    updateDeviceFaults([...countDeviceFaults(filteredFaults.device).values()])
  }

  const onSearchValueChange = (event) => {
    updateSearchStr(event.target.value)
  }

  const asteriskLegend = intl.formatMessage({
    id: 'faultReport.selectedPeriodsInfo.asterisk',
  })

  const showSelectedTable = () => {
    switch (searchResultCategory) {
      case 'component':
        return (
          <ComponentFaults
            faults={componentFaults}
            selectedFault={selectedFault}
            setFault={setFault}
            intl={intl}
          />
        )
      case 'device':
        return (
          <DeviceFaults
            faults={deviceFaults}
            selectedFault={selectedFault}
            setFault={setFault}
            intl={intl}
          />
        )
      case 'harness':
        return (
          <HarnessFaults
            faults={harnessFaults}
            selectedFault={selectedFault}
            setFault={setFault}
            intl={intl}
          />
        )
      case 'generic':
        return (
          <OtherFaults
            faults={otherFaults}
            selectedFault={selectedFault}
            setFault={setFault}
            intl={intl}
          />
        )
    }
  }

  return (
    <>
      <Loadable isLoading={isLoadingTerminology} showText={false}>
        <Flex vertical={false} gap="middle">
          <Input
            data-testid="search-string"
            placeholder={intl.formatMessage({
              id: 'faultReport.inputSearchTextPlaceholder',
            })}
            allowClear
            onPressEnter={onSearch}
            onChange={onSearchValueChange}
            value={searchStr}
          />
          <Button onClick={onSearch} type="primary">
            <FormattedMessage id="faultReport.search" />
          </Button>
        </Flex>
        <Radio.Group
          className="report-switch"
          value={searchResultCategory}
          buttonStyle="solid"
          size="middle"
          onChange={(event) => setSearchResultCategory(event.target.value)}
        >
          <Radio.Button value={SEARCH_RESULT_CATEGORY.component}>
            <Flex
              data-testid="results-switch-components"
              vertical={false}
              gap="middle"
              className="switch-radio-option"
            >
              <FormattedMessage id="faultReport.componentFaultsHeaders.components" />
              <Counter results={componentFaults} theme={theme} />
            </Flex>
          </Radio.Button>
          <Radio.Button value={SEARCH_RESULT_CATEGORY.device}>
            <Flex
              data-testid="results-switch-devices"
              vertical={false}
              gap="middle"
              className="switch-radio-option"
            >
              <FormattedMessage id="faultReport.deviceFaultsHeaders.device" />
              <Counter results={deviceFaults} theme={theme} />
            </Flex>
          </Radio.Button>
          <Radio.Button value={SEARCH_RESULT_CATEGORY.harness}>
            <Flex
              vertical={false}
              gap="middle"
              data-testid="results-switch-harness"
              className="switch-radio-option"
            >
              <FormattedMessage id="faultReport.harnessFaultsHeaders.harness" />
              <Counter results={harnessFaults} theme={theme} />
            </Flex>
          </Radio.Button>
          <Radio.Button value={SEARCH_RESULT_CATEGORY.generic}>
            <Flex
              data-testid="results-switch-generic"
              vertical={false}
              gap="middle"
              className="switch-radio-option"
            >
              <FormattedMessage id="faultReport.otherFaultsHeaders.generic" />
              <Counter results={otherFaults} theme={theme} />
            </Flex>
          </Radio.Button>
        </Radio.Group>
        <Flex gap={30} vertical={true} className="reports-table-container">
          <Flex vertical={true} className="fault-table">
            <span className="fault-report-legend">
              <img
                style={{ width: '10px' }}
                src={iconRedAsterisk}
                alt="asterisk"
              />
              {asteriskLegend}
            </span>
            {showSelectedTable()}
          </Flex>
          <Flex vertical={true} className="selected-fault-table">
            <SelectedFault fault={selectedFault} intl={intl} />
            <VehicleTestTable
              data={selectedFault.problems}
              intl={intl}
              setFault={setFault}
              parentFault={selectedFault}
            />
          </Flex>
        </Flex>
      </Loadable>
    </>
  )
}

function ReportsPage({ intl }) {
  const { defaultAlgorithm } = antdTheme
  const [searchTerm, setSearchTerm] = useLocalStorage('reports-query', {
    stationIds: [],
    makeModelIds: [],
    zones: [],
    teams: [],
    department: null,
    start_at: null,
    end_at: null,
    shift: null,
    period: { start: null, end: null },
  })
  const history = useHistory()
  let { makeModelIds, stationIds, start_at, end_at, zones, teams, department } =
    searchTerm
  const [station_alerts] = useLocalStorage('station-alert', 'send,skip')
  const [plants] = useUserPlant()
  const [testZones] = useUserTestZones()
  const { isSuccess, isLoading, isFetching, faults } = useFaults({
    makeModelIds,
    stationIds,
    start_at,
    end_at,
    station_alerts,
    plants,
    zones,
    teams,
    department,
    testZones: !!testZones ? testZones.map((tz) => tz.specifier) : [],
  })
  const [periods, setPeriods] = React.useState([])
  const [report, setReport] = React.useState({})

  React.useEffect(() => {
    if (!faults) return
    setReport(groupFaults(faults))
    return () => setPeriods([])
  }, [faults])

  React.useEffect(() => {
    if (!report) return
    setPeriods(getAllPeriods(report))
  }, [report])

  const getAllPeriods = (report) => {
    const allPeriods = []
    for (const [day, hours] of Object.entries(report)) {
      for (const hour of Object.keys(hours)) {
        allPeriods.push({ day, hour })
      }
    }
    return allPeriods
  }

  return (
    <Flex vertical={true} id="reports-page">
      <Flex
        vertical={false}
        justify="flex-start"
        gap={10}
        className="reports-breadcrumb-wrapper"
      >
        <LeftOutlined onClick={() => history.push('/')} />
        <div>
          <Breadcrumb>
            <Breadcrumb.Item>
              <HomeOutlined />
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <FormattedMessage id="faultReport.header" />
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </Flex>
      <Row className="reports-page-body">
        <Col span={12} className="reports-page-body-left">
          <ConfigProvider
            theme={{
              algorithm: defaultAlgorithm,
              components: {
                Collapse: {
                  headerBg: '#f2f2f2',
                },
                Input: {
                  colorBgContainer: WHITE_COLOR,
                },
                Button: {
                  colorBgContainer: WHITE_COLOR,
                  defaultHoverBg: WHITE_COLOR,
                  colorPrimary: BRAND_BLUE,
                  primaryColor: WHITE_COLOR,
                  colorPrimaryHover: '#4096ff',
                },
                Select: {
                  colorBgContainer: WHITE_COLOR,
                  colorBgElevated: WHITE_COLOR,
                },
              },
            }}
          >
            <ReportControl
              intl={intl}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              faults={faults}
            />
          </ConfigProvider>
          <Loadable showText={false} isLoading={isLoading || isFetching}>
            {isSuccess && (
              <FailuresOverview
                intl={intl}
                periods={periods}
                setPeriods={setPeriods}
                data={report}
              />
            )}
          </Loadable>
        </Col>
        <Col span={12} className="reports-page-body-right">
          {periods?.length > 0 && Object.keys(report).length > 0 ? (
            <Faults report={report} periods={periods} intl={intl} />
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={intl.formatMessage({
                id: 'faultReport.noSelection',
              })}
            />
          )}
        </Col>
      </Row>
    </Flex>
  )
}

export default requireAuthentication(injectIntl(ReportsPage))
