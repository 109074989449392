import axios from 'axios'

import { userLanguageInterceptor } from './interceptors'

const controller = axios.create({
  baseURL: '/tracer',
})

controller.interceptors.request.use(userLanguageInterceptor)

const api = axios.create({
  baseURL: '/api',
})

api.interceptors.request.use(userLanguageInterceptor)

export { controller, api }
