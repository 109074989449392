import { controller } from './apis'

export function apiPostRelatedDTCsRequest({ makeModelId, circuits }) {
  return controller
    .post(`/make-model-id/${makeModelId}/dtcs`, {
      circuits: circuits.map((circuit) => ({
        circuit,
      })),
    })
    .then((resp) => resp.data)
}

export function apiPostRelatedCircuitsRequest({ makeModelId, dtcs }) {
  return controller
    .post(`/make-model-id/${makeModelId}/circuits`, { dtcs })
    .then((resp) => resp.data)
}
